import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../components/bootstrap/Alert';
import Button from '../../components/bootstrap/Button';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Logo from '../../components/Logo';
import useDarkMode from '../../hooks/useDarkMode';
import useSession from '../../hooks/useSession';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import { LOGIN } from '../../queries/login';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
  if (isNewUser) {
    return (
      <>
        <div className='text-center h1 fw-bold mt-5'>Create Account,</div>
        <div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
      </>
    );
  }
  return (
    <>
      <div className='text-center h1 fw-bold mt-5'>Welcome,</div>
      <div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
    </>
  );
};

const Login = ({ isSignUp }) => {
  const { darkModeStatus } = useDarkMode();

  const [usernameInput, setUsernameInput] = useState(false);
  const [isNewUser, setIsNewUser] = useState(isSignUp);
  const [errors, setErrors] = useState([]);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const { dispatch } = useSession();

  const [login] = useMutation(LOGIN);

  const handleOnClick = async () => {
    const { data, errors } = await login({
      variables: {
        ...user
      }
    });

    if ( errors ) {
      setErrors(data.errors);
    }

    if ( data?.login?.token ) {
      dispatch(data.login.token);
    }
  };

  return (
    <PageWrapper
      title={isNewUser ? 'Sign Up' : 'Login'}
      className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}
    >
      <Page className='p-0'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
            <Card className='shadow-3d-dark'>
              <CardBody>
                <div className='text-center my-5'>
                  <Link
                    to='/'
                    className={classNames(
                      'text-decoration-none  fw-bold display-2',
                      {
                        'text-dark': !darkModeStatus,
                        'text-light': darkModeStatus,
                      },
                    )}>
                    <Logo width={200} />
                  </Link>
                </div>
                <div className='bg-l10-dark rounded-3'>
                  <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
                    <div className='col'>
                      <Button
                        color='dark'
                        isLight={!!isNewUser}
                        className='rounded-1 w-100'
                        size='lg'
                        onClick={() => {
                          setUsernameInput(false);
                          setIsNewUser(!isNewUser);
                        }}>
                        Login
                      </Button>
                    </div>
                    <div className='col'>
                      <Button
                        color='dark'
                        isLight={!isNewUser}
                        className='rounded-1 w-100'
                        size='lg'
                        onClick={() => {
                          setUsernameInput(false);
                          setIsNewUser(!isNewUser);
                        }}>
                        Sign Up
                      </Button>
                    </div>
                  </div>
                </div>

                <LoginHeader isNewUser={isNewUser} />

                <form className='row g-4' onSubmit={handleOnClick}>
                  {isNewUser ? (
                    <>
                      <div className='col-12'>
                        <FormGroup
                          id='signup-email'
                          isFloating
                          label='Your email'>
                          <Input type='email' autoComplete='email' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup
                          id='signup-name'
                          isFloating
                          label='Your name'>
                          <Input autoComplete='given-name' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup
                          id='signup-surname'
                          isFloating
                          label='Your surname'>
                          <Input autoComplete='family-name' />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <FormGroup
                          id='signup-password'
                          isFloating
                          label='Password'>
                          <Input
                            type='password'
                            autoComplete='password'
                          />
                        </FormGroup>
                      </div>
                      <div className='col-12'>
                        <Button
                          color='info'
                          className='w-100 py-3'
                          onClick={handleOnClick}>
                          Sign Up
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-12'>
                        {!usernameInput ? (
                          <FormGroup
                            id='login-email'
                            isFloating
                            label='Your email'>
                            <Input
                              autoComplete='email'
                              value={user.email}
                              onChange={(event) => setUser((prev => ({ ...prev, email: event.target.value }) )) }
                            />
                          </FormGroup>
                        ) : (
                          <FormGroup
                            id='login-password'
                            isFloating
                            label='Password'>
                            <Input
                              type='password'
                              autoComplete='password'
                              value={user.password}
                              onChange={(event) => setUser((prev => ({ ...prev, password: event.target.value }) )) }
                            />
                          </FormGroup>
                        )}
                      </div>

                      { errors.length > 0 &&
                        <Alert title="Errors">
                          { errors?.map((err, errIndex) =>
                              <div key={`error_${errIndex}`}>
                                { err.message }
                              </div>
                          ) }
                        </Alert>
                      }

                      <div className='col-12'>
                        {!usernameInput ? (
                          <Button
                            color='warning'
                            className='w-100 py-3'
                            onClick={() => setUsernameInput(true)}>
                            Continue
                          </Button>
                        ) : (
                          <Button
                            color='warning'
                            className='w-100 py-3'
                            onClick={handleOnClick}>
                            Login
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              </CardBody>
            </Card>
            <div className='text-center'>
              <a
                href='/'
                className={classNames('text-decoration-none me-3', {
                  'link-light': isNewUser,
                  'link-dark': !isNewUser,
                })}>
                Privacy policy
              </a>
              <a
                href='/'
                className={classNames('link-light text-decoration-none', {
                  'link-light': isNewUser,
                  'link-dark': !isNewUser,
                })}>
                Terms of use
              </a>
            </div>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
