import { useQuery } from '@apollo/client';
import { formatDistance, formatRelative } from 'date-fns';
import { decode } from 'jsonwebtoken';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import USERS from '../../common/data/userDummyData';
import Button from '../../components/bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../components/bootstrap/Dropdown';
import Progress from '../../components/bootstrap/Progress';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useSession from '../../hooks/useSession';
import { ME } from '../../queries/me';

export const UserAvatar = ({ srcSet, src }) => {
  return (
    <div className='user-avatar'>
      <img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
    </div>
  );
};
UserAvatar.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
  srcSet: null,
};

const User = () => {
  const [sessionProgress, setSessionProgress] = useState({ min: 0, max: 1, value: 0, percentage: 100, time: '', remaining: '' });

  const history = useHistory();
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();

  const { session, dispatch } = useSession();

  const { data, loading, error } = useQuery(ME);
  const user = data?.me;

  const calculateSessionProgress = useCallback(() => {
    const { exp, iat } = decode(session);

    const now = new Date().getTime() / 1000;
    setSessionProgress({
      min: iat,
      max: exp,
      value: now,
      percentage: Number(100 - (((now - iat) / (exp - iat)) * 100)),
      time: formatRelative(exp * 1000, now * 1000),
      remaining: formatDistance(exp * 1000, now * 1000, { includeSeconds: true }),
    });

    if ( !session ) {
      dispatch(null, 'Your session token was deleted');
    }

    if ( exp <= now ) {
      dispatch(null, 'Your session has expired, login again to continue.');
    }

  }, [ session ]);

  useEffect(() => {
    calculateSessionProgress();

    const interval = setInterval(calculateSessionProgress, 5 /* mins */ * 60 * 1000);

    return () => clearInterval(interval);
  }, [ calculateSessionProgress ]);

  if( error?.message ) {
    dispatch(null)
    return <div></div>;
  }

  if (loading) {
    return <div></div>;
  }

  return user && (
    <Dropdown>
      <DropdownToggle hasIcon={false}>
        <div>
          <div className='user'>
            <UserAvatar srcSet={USERS.JOHN.srcSet} src={USERS.JOHN.src} />
            <div className='user-info'>
              <div className='user-name'>{user.name}</div>
              <div className='user-sub-title'>{user.email}</div>
            </div>
          </div>
          <Tooltips
            title={
              `Session expires in ${sessionProgress.remaining}
              (${sessionProgress.time})`
            }
            placement="right"
          >
            <Progress height={4} value={sessionProgress.percentage} min={0} max={100} color="primary" />
          </Tooltips>
        </div>
      </DropdownToggle>
      <DropdownMenu
        isAlignmentEnd
        direction="end"
      >
        <DropdownItem>
          <Button
            icon='AccountBox'
            onClick={() =>
              history.push(
                `/profile`,
              )
            }>
            Profile
          </Button>
        </DropdownItem>
        <DropdownItem>
          <Button
            icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
            onClick={() => setDarkModeStatus(!darkModeStatus)}
            aria-label='Toggle fullscreen'>
            {darkModeStatus ? 'Dark Mode' : 'Light Mode'}
          </Button>
        </DropdownItem>
        <DropdownItem isDivider />
        <DropdownItem>
          <Button icon='Logout' onClick={() => dispatch(null)}>
            Logout
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
