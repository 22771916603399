import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import Spinner from '../../../components/bootstrap/Spinner';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import { GET_WEBSITE_BY_ID } from '../../../queries/websites';
import CommonHeaderRight from './CommonHeaderRight';

const WebsiteHeader = () => {
  const { id } = useParams();
  const { data: d, loading } = useQuery(GET_WEBSITE_BY_ID, { variables: { id } });
  const website = d?.website;

	return (
		<Header>
			<HeaderLeft>
        { loading &&
          <Spinner />
        }
        { !loading && <>
          <h4 style={{ margin: 0 }}><b>{ website.name || website.title || website.domain.value }</b></h4>
        </> }
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default WebsiteHeader;
