import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';

import COLORS from '../common/data/enumColors';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import ThemeContext from '../contexts/themeContext';
import { getOS } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import useSession from '../hooks/useSession';
import Aside from '../layout/Aside/Aside';
import Portal from '../layout/Portal/Portal';
import Wrapper from '../layout/Wrapper/Wrapper';
import { demoPages, layoutMenu } from '../menu';
import Login from '../pages/auth/Login';

function App() {
  getOS();

  const location = useLocation();
  const { session } = useSession();

  const apolloClient = new ApolloClient({
    uri: 'http://localhost:8080/graphql',
    cache: new InMemoryCache(),
    connectToDevTools: true,
    headers: {
      Authorization: session,
    },
    formatError: (err) => {
      // Don't give the specific errors to the client.
      if (err.message.startsWith('Database Error: ')) {
        return new Error('Internal server error');
      }
      // Otherwise return the original error. The error can also
      // be manipulated in other ways, as long as it's returned.
      return err;
    }
  });

  /**
   * Dark Mode
   */
  const { themeStatus, darkModeStatus } = useDarkMode();
  const theme = {
    theme: themeStatus,
    primary: COLORS.PRIMARY.code,
    secondary: COLORS.SECONDARY.code,
    success: COLORS.SUCCESS.code,
    info: COLORS.INFO.code,
    warning: COLORS.WARNING.code,
    danger: COLORS.DANGER.code,
    dark: COLORS.DARK.code,
    light: COLORS.LIGHT.code,
  };

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute('theme', 'dark');
    }
    return () => {
      document.documentElement.removeAttribute('theme');
    };
  }, [darkModeStatus]);

  /**
   * Full Screen
   */
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
      document.body.classList.add('modern-design');
    } else {
      document.body.classList.remove('modern-design');
    }
  });

  //	Add paths to the array that you don't want to be "Aside".
  const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path];

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        { session
          ? <ToastProvider components={{ ToastContainer, Toast }}>
            <div
              ref={ref}
              className='app'
              style={{
                backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
                zIndex: fullScreenStatus && 1,
              }}>
              <Switch location={location}>
                {withOutAsidePages.map((path) => (
                  <Route key={path} path={path} exact component={Wrapper} />
                ))}
                <Route>
                  <Aside />
                  <Wrapper />
                </Route>
              </Switch>
            </div>
            <Portal id='portal-notification'>
              <ReactNotification />
            </Portal>
          </ToastProvider>
          : <Login />
        }
      </ApolloProvider>
    </ThemeProvider>
  )
};

export default App;
