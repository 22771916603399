import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';

import showNotification from '../components/extras/showNotification';

const SessionContext = createContext({ session: null, dispatch: null });

export const SessionContextProvider = ({ children }) => {
  const [session, setSession] = useState(localStorage.getItem('session'));

  useEffect(() => {
    if (session !== null ) {
      localStorage.setItem('session', session);
    } else {
      localStorage.removeItem('session');
    }
  }, [session]);

  return (
    <SessionContext.Provider
      value={{
        session,
        dispatch: (token = null, reason = false) => {
          if ( reason ) {
            showNotification(
              "Logged Out",
              'You were logged out due to your session expiring.',
            );
          }
          setSession(token);
        }
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionContext;
