import { gql } from '@apollo/client';

export const GET_WEBSITES = gql`
  query {
    websites {
      id
      name
      title
      logo
      demo
      domain {
        value
        verified
      }
      features
    }
  }
`;

export const GET_WEBSITE_BY_ID = gql`
  query getWebsite($id: String!) {
    website(id: $id) {
      id
      name
      title
      logo
      demo
      owner
      domain {
        value
      }
      meta {
        title
      }
      theme {
        navigation {
          background {
            color
          }
        }
      }
      features
    }
  }
`;

export const GET_WEBSITE_SETTINGS = gql`
  query getWebsite($id: String!) {
    website(id: $id) {
      id
      name
      title
      logo
      demo
      owner
      domain {
        value
        verified
      }
      meta {
        title
        description
        icon
        author
        tags
      }
      lead {
        website_uuid
      }
      pwa {
        name
        icon
        background
      }
      testDomains {
        id
        name
        value
        default
      }
      accessTokens {
        level
        name
        token
      }
      features
    }
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation UpdateWebsite($websiteId: String, $payload: WebsiteInput) {
    updateWebsite(websiteId: $websiteId, payload: $payload) {
      id
      name
      title
      logo
      demo
      domain {
        value
        verified
      }
      meta {
        title
        description
        icon
        author
        tags
      }
      pwa {
        name
        icon
        background
      }
      testDomains {
        id
        name
        value
        default
      }
      accessTokens {
        level
        name
        token
      }
    }
  }
`
