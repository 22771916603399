import './i18n';
import './styles/styles.scss';

import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App/App';
import { SessionContextProvider } from './contexts/sessionContext';
import { ThemeContextProvider } from './contexts/themeContext';
import reportWebVitals from './reportWebVitals';

render(
  <Router>
    <StrictMode>
      <SessionContextProvider>
        <ThemeContextProvider>
          <App />
        </ThemeContextProvider>
      </SessionContextProvider>
    </StrictMode>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
